body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: black; */

  background: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
}

.ant-menu-light .ant-menu-item-selected {
  color: black !important;
  /* background-color: rgba(37, 39, 40, 0.8) !important; */
  background-color: rgba(37, 39, 40, 0) !important;
}

.ant-menu-item-active {
  color: red !important;
  /* background-color: rgba(37, 39, 40, 0.8) !important; */
  background-color: rgba(37, 39, 40, 0) !important;
}

.ant-menu-submenu .ant-menu-submenu-inline .ant-menu-submenu-active {
  color: red !important;
  /* background-color: rgba(37, 39, 40, 0.8) !important; */
  background-color: rgba(37, 39, 40, 0) !important;
}

.ant-btn-primary {
  /* background-color: rgba(37, 39, 40, 0.8); */
  background-color: #00B5C9;
  box-shadow: none;
}

.ant-breadcrumb-link {
  color: white !important;
  font-size: 20px;
  left: 180px;
  top: -50px;
  position: relative
}

/* @media (max-width: 767px) { */
  .ant-menu .ant-menu-item  { 
    /* background-color: rgba(174, 181, 176, 0.4); */
    height: 70px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.6) !important; 
    border-radius: 0px;
    padding-left: 5px !important;
    padding-right: 0px !important;
    /* width: 85%; */
    /* left: 25px; */
  }

  .ant-menu-submenu .ant-menu-submenu-title {   
    /* background-color: rgba(174, 181, 176, 0.4); */
    height: 70px !important;

    border-bottom: 1px solid rgba(255, 255, 255, 0.6) !important; 
    border-radius: 0px;
    /* width: 85%; */
    /* left: 25px; */
    padding-left: 5px !important;
    padding-right: 0px !important;
    
  }

  
  .ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)
  {
    color: red !important;
  }

  .ant-menu-submenu .ant-menu-item {
    /* background-color: rgba(174, 181, 176, 0.2); */
    padding-left: 40px !important; 
    /* font-size: 20px; */

    border-bottom: none !important; 
    border-radius: 0px;
    /* width: 85%; */
    /* left: 25px; */
  }

  /* .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title, .ant-menu-light>.ant-menu .ant-menu-submenu-selected {
    color: white
  } */

  /* .ant-menu-submenu-inline */
  .ant-menu-inline .ant-menu-submenu-inline .ant-menu {
    max-height: 100%; /* Set a max-height according to your preference */
    overflow-y: auto; /* Adds a vertical scrollbar */
    padding-inline: 0px !important;
  }

  .ant-menu-submenu-arrow {
    transform: scale(2) !important;
  }

  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover{
    background-color: transparent;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }

  .ant-space-item {
    width: 100%;
    display: flex;

  }

  .code-card {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.code-card code {
  color: #333; /* Dark grey for the code text */
  background-color: transparent;  /* to override any existing background-color styles */
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector  {
  border: 1px !important;
  /* border-color: #333 !important; */
  /* padding-bottom: 1px !important; */
  /* padding-top: 1px !important; */
}

.code-card {
  max-width: 100%; /* Set the maximum width of the code card */
  overflow-x: auto; /* Make it scrollable horizontally */
}

/* Or if you want to break long lines and wrap the text: */
pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

/* } */
